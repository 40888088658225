<template>
  <div class="dashboard-container">
    <TheUserDashboardMenu />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import TheUserDashboardMenu from "@/components/TheUserDashboardMenu";
export default {
  name: "UserDashboard",
  components: {
    TheUserDashboardMenu
  },
  data() {
    return {};
  },

  computed: {},
  methods: {},
  mounted() { },
  beforeCreate() { },
  destroyed() { },
};
</script>

<style lang="scss" scoped>
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
</style>
