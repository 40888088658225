<template>
  <div class="dashboard-menu" v-if="loaded">
    <div class="left">
      <h2>Hello, {{ userInfo.firstName }}</h2>
      <p class="pdga-details" v-if="userInfo.profile.pdgaNumber">
        PDGA number:
        <b>{{ userInfo.profile.pdgaNumber }}</b>
        <span
          >Rating:
          <b v-if="userInfo.profile.pdgaRating">{{
            userInfo.profile.pdgaRating
          }}</b>
          <template v-else>-</template></span
        >
      </p>
    </div>
    <div class="right">
      <!-- currently not utilized -->
    </div>

    <nav>
      <ul class="tour-nav" :class="navClass()">
        <router-link :to="{ name: 'dashboard-activity' }">
          <li class="_activity">Activity</li>
        </router-link>
        <router-link :to="{ name: 'dashboard-progress' }">
          <li class="_progress">Progress</li>
        </router-link>
        <router-link :to="{ name: 'dashboard-starred' }">
          <li class="_starred">Starred events</li>
        </router-link>
      </ul>
    </nav>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "TheUserDashboardMenu",
  components: {},
  data() {
    return {
      loaded: false,
      userInfo: null,
    };
  },
  computed: {},
  methods: {
    async loadData() {
      const userInfo = await this.$axios({
        headers: {
          Authorization: this.$store.state.user.sessionInfo.token,
        },
        data: {
          query: `
          {
            me{
              firstName
              lastName
              id
              profile{
                pdgaNumber
                pdgaRating
              }
            }
          }
          `,
        },
      });

      this.userInfo = userInfo.data.data.me;
      this.loaded = true;
    },
    navClass() {
      if (this.$route.name == "dashboard-activity") return "_activity";
      if (this.$route.name == "dashboard-progress") return "_progress";
      if (this.$route.name == "dashboard-starred") return "_starred";

      return "";
    },
  },
  mounted() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";

.dashboard-menu {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;

  .right {
    display: none;
  }
  .left {
    width: 100%;
    padding: 32px 18px 0 18px;
  }

  nav {
    padding: 28px 0 18px 0;
    display: flex;
    width: 100%;
    margin: 0 18px 0 18px;

    ul {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 0;
      list-style: none;
      position: relative;

      a {
        color: $midnight;
        &:hover {
          text-decoration: none;
        }
      }

      .router-link-exact-active {
        li {
          position: relative;
          &::after {
            content: "";
            height: 2px;
            background: $midnight;
            position: absolute;
            bottom: -4px;
            left: 0;
            z-index: 3;
            transition: all ease 0.3s;
            width: 100%;
          }
        }
      }

      li {
        color: $midnight;
        font-size: 16px;
        position: relative;
        display: flex;
        justify-content: center;
        margin-right: 18px;

        &._activity {
          width: 54px;
        }
        &._progress {
          width: 62px;
        }
        &._starred {
          width: 108px;
        }
      }
    }
  }
}

.pdga-details {
  margin-top: 12px;
  span {
    margin-left: 10px;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .dashboard-menu {
    padding: 0;

    .left {
      width: 75%;
      margin-top: 0;
      padding-right: 24px;
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    nav {
      padding: 0px;
      display: flex;
      width: 100%;
      margin: 48px 0 64px 0;
      border: none;

      &.rounds-left {
        padding-right: 0;
        width: 40%;
      }

      &.rounds-right {
        padding: 0;
        width: 60%;
        ul {
          justify-content: flex-end;
          align-items: center;
          padding: 0;

          .router-link-exact-active {
            li {
              background: $ocean;
              color: white;
              @include Gilroy-Bold;
            }
          }

          li {
            font-size: 16px;
            background: $mist;
            border-radius: 16px;
            padding: 6px 16px;
            margin-right: 12px;
            transition: all ease 0.3s;
            &.active {
              background: $ocean;
              color: white;
              @include Gilroy-Bold;
            }
            &::after {
              display: none;
            }
          }
        }
      }
      ul {
        border-top: 1px solid $fog;
        border-bottom: 1px solid $fog;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding: 16px 0 0 0;
        list-style: none;
        &::after {
          content: "";
          height: 3px;
          background: $midnight;
          position: absolute;
          bottom: -2px;
          left: 0;
          z-index: 3;
          transition: all ease 0.3s;
        }

        &._activity {
          &::after {
            width: 62px;
            left: 0;
          }
        }
        &._progress {
          &::after {
            width: 72px;
            left: 94px;
          }
        }

        &._starred {
          &::after {
            width: 122px;
            left: 198px;
          }
        }

        li {
          font-size: 18px;
          padding-bottom: 16px;
          margin-right: 32px;
          padding: 0 0px 16px 0px;
          &:hover {
            &::after {
              content: "";
              height: 3px;
              background: $sleet;
              position: absolute;
              bottom: -1px;
              left: 0;
              z-index: 2;
              transition: all ease 0.3s;
              width: 100%;
            }
          }
          &._activity {
            width: 62px;

            display: flex;
            justify-content: center;
          }
          &._progress {
            width: 72px;
            display: flex;
            justify-content: center;
          }
          &._starred {
            width: 122px;
            display: flex;
            justify-content: center;
          }
          &.results {
            width: 62px;
            display: flex;
            justify-content: center;
          }
          &.rounds {
            width: 64px;
            display: flex;
            justify-content: center;
          }
          &.players {
            width: 64px;
            display: flex;
            justify-content: center;
          }
          &.details {
            width: 60px;
            display: flex;
            justify-content: center;
          }
        }

        .router-link-exact-active {
          li {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
